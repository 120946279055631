<template>
  <section class="hero career-hero">
    <div class="career-hero-box">
      <div class="hero-border-left"></div>

      <div class="hero-block fade-in-bck">
        <div class="hero-title fade-in-bck">
          CAREERS
          <span class="hero-title-bar fade-in-bck"></span>
        </div>

        <div class="hero-subtitle">
          Be part of our mission to make creation on the web easier and better.
          <a
            data-mixpanel="portfolio_cta_get_free_consultation"
            href="mailto:clark@countable.ca"
            class="main-portfolio-cta btn-countable btn-outline btn-outline-red"
            >APPLY NOW</a
          >
        </div>
      </div>
    </div>
    <div class="astronaut-wrapper">
      <div class="jupiter-orbit">
        <img
          src="@/assets/media/career/002-jupiter.svg"
          class="planet-jupiter"
        />

        <img src="@/assets/media/career/003-ufo.svg" class="ufo" />
      </div>

      <img
        src="@/assets/media/landing/astronaut.png"
        class="career-astronaut floating"
        alt="Countable's astronaut"
      />
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
