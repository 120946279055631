<template>
  <main class="portfolio career">
    <Banner />
    <Benefits />

    <template v-for="(teamMember, index) in teamTestimonial">
      <!-- Render Career Section -->
      <OurValues v-if="index === 1" :key="index" />
      <ApplyNow v-else-if="index === 2 || index >= 4" :key="index" />
      <TeamSection v-else-if="index === 3" :key="index" />
      <!-- End of Render Career Section -->

      <!-- Render Team Member Testimonial -->
      <TeamTestimonial
        :member-info="teamMember"
        :key="`${index}-${teamMember.name}`"
        :is-right="isRight(index)"
      />
      <!-- End of Render Team Member Testimonial -->

      <section
        v-if="index + 1 === teamTestimonial.length"
        class="portfolio-section cta-section"
        :key="index"
      >
        <div class="cta-section-title">Interested? Check the Applicant's guide!</div>

        <a
          class="btn-countable btn-cta"
          href="https://countable-ops-manual.readthedocs.io/peopleops/recruiting/APPLICANT_GUIDE.html?highlight=applicant#applicant-guide"
          target="_blank"
        >Applicant's Guide</a>
      </section>
    </template>
  </main>
</template>

<script>
import Banner from "@/components/Careers/Banner";
import ApplyNow from "@/components/Careers/ApplyNow";
import Benefits from "@/components/Careers/Benefits";
import OurValues from "@/components/Careers/OurValues";
import TeamSection from "@/components/Careers/TeamSection";
import TeamTestimonial from "@/components/Careers/TeamTestimonial";

export default {
  components: {
    Banner,
    ApplyNow,
    Benefits,
    OurValues,
    TeamSection,
    TeamTestimonial
  },
  computed: {
    teamTestimonial() {
      return this.$store.getters.getTeamList.filter(
        member => member.testimonial !== null
      );
    }
  },
  methods: {
    isRight(index) {
      return index % 2 == 0;
    }
  },
  mounted() {
    this.$store.dispatch("fetchTeamMemberAPI");
  }
};
</script>

<style></style>
