<template>
  <section class="portfolio-section career-team-section">
    <div class="portfolio-section-column-left">
      <div class="portfolio-wrapper">
        <div class="portfolio-section-title">TEAM</div>
        <div class="portfolio-section-description career-description">
          We're driven by the idea that amazing work is born every time you
          combine creativity, fun and hard work. Meet the team that makes this
          magic happen.
        </div>
      </div>
    </div>

    <div class="portfolio-section-column-right">
      <div class="portfolio-section rev" id="team">
        <div class="team-wrapper">
          <TeamMember v-for="member in memberList" :key="member.id" :member-info="member" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TeamMember from "../TeamMember";
export default {
  components: {
    TeamMember
  },
  computed: {
    memberList() {
      return this.$store.getters.getTeamList;
    }
  }
};
</script>

<style></style>
