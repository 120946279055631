<template>
  <section
    v-if="memberInfo"
    class="parallax-section testimonial-parallax"
    :class="`${memberInfo.image ? 'member-testimonial' : ''} ${isRight ? 'testimonial-right' : ''}`"
    :style="`--background: url(${returnImage(memberInfo.image)})`"
  >
    <div class="testimonial">
      <div class="question">What's special about working at Countable?</div>
      <div class="answer">{{ memberInfo.testimonial }}</div>
      <div class="author">{{ memberInfo.name }}, {{ memberInfo.role }}</div>
    </div>
  </section>
</template>

<script>
import API from "@/api/utils";

export default {
  props: {
    memberInfo: {
      type: Object,
      required: true
    },
    isRight: {
      type: Boolean
    }
  },
  methods: {
    returnImage(img) {
      const imgPath = img ? img.url : "";
      const host = API.getBaseURL();
      return `${host}${imgPath}`;
    }
  }
};
</script>

<style></style>
