<template>
  <section class="portfolio-section">
    <div class="portfolio-section-column-left">
      <div class="portfolio-wrapper">
        <div class="portfolio-section-title">BENEFITS</div>
        <div class="portfolio-section-description career-description">
          Here at countable we believe you work better when you feel good. These
          are some benefits we offer to our team:
        </div>
      </div>
    </div>

    <div class="portfolio-section-column-right">
      <div class="benefits">
        <div class="benefit">
          <i class="fas fa-book"></i>
          <p>Rapid Learning and Skill advancement</p>
        </div>
        <div class="benefit">
          <i class="fas fa-home"></i>
          <p>Work from Home and flexible hours</p>
        </div>
        <div class="benefit">
          <i class="fas fa-users"></i>
          <p>Great culture and high skilled team members</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
