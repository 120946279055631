<template>
  <section class="portfolio-section">
    <div class="portfolio-section-column-left">
      <div class="portfolio-wrapper">
        <div class="portfolio-section-title">OUR VALUES</div>
        <div class="portfolio-section-description career-description">
          Here at countable we value work-life balance, continuous improvement,
          flexible hours and interesting projects.
        </div>
      </div>
    </div>

    <div class="portfolio-section-column-right">
      <video style="height: 100%; box-shadow: unset;" controls class="portfolio-img">
        <source src="@/assets/video/countable_video.mp4" type="video/mp4" />
        <!--Use additional source tags to provide additional formats-->
      </video>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
