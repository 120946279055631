<template>
  <section class="portfolio-section cta-section">
    <div class="cta-section-title">Interested? Apply Now!</div>

    <a
      class="btn-countable btn-cta"
      href="mailto:clark@countable.ca"
      data-mixpanel="career_apply_form"
      >Apply Now</a
    >
  </section>
</template>

<script>
export default {};
</script>

<style></style>
